import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import favicon from '../assets/images/about.png'
import image from '../assets/images/index3.png'

function SEO({ data }) {
  return (
    <Helmet title="Oliver Feher - Sr. Software Engineer">
      <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      <meta name="description" content="{data.description}" />
      <meta name="og:title" content="Oliver Feher - Sr. Software Engineer" />
      <meta name="og:description" content="Oliver Feher - Sr. Software Engineer" />
      <meta name="og:image:width" content="256"/> 
      <meta name="og:image:height" content="128" />
      <meta name="og:image" content={image} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:title" content="Oliver Feher - Sr. Software Engineer"/>
      <meta name="twitter:description" content="Oliver Feher - Sr. Software Engineer"  />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string
}

export default SEO;
