// DEPENDENCY IMPORTS
import React from 'react';

//  COMPONENT IMPORTS
import Footer from './Footer';
import Header from './Header';
import Seo from "./seo"
// STYELSHEET IMPORT
import '../assets/style.scss'
import './about.scss'
import './header.scss'

const Layout = ({ children, hideNav}) => {

  const layoutStyle = {
    width: '100%',
  }
  return (
    <> 
      <Seo/>
      <main className="main-content-extension" style={layoutStyle}>
        {hideNav ? null : <Header/>}
          {children}
        <Footer />
      </main>
    </>
  )
}


export default Layout;
