import React from 'react';
import Layout from '../components/layout';

import '../components/about.scss';

import Pic from '../assets/images/about.png';
import ResumePic from '../assets/images/about-resume-pic.png';
import Resume from '../assets/resume.pdf';

const AboutPage = ({ data }) => {

    return (
        <Layout hideNav={false}>
            <main id="about-container">
                <div className="about-hero-container">
                    <div className="about-image-container">
                        <h2>About</h2>
                        <img src={Pic} alt="about portrait"/>
                    </div>

                    <div className="border-line"></div>
                </div>

                <div className="about-description" dangerouslySetInnerHTML={{__html: "<div>As a Director of Engineering, I have a passion for leading teams to develop innovative products that drive business growth. My expertise lies in managing the entire product development lifecycle, from ideation to launch, while ensuring that the engineering team is aligned with the company's overall strategy and vision.<br><br> Throughout my career, I have demonstrated the ability to manage large-scale projects and cross-functional teams, while staying focused on delivering high-quality solutions on time and within budget. I have a proven track record of building and motivating high-performance engineering teams that consistently exceed expectations and deliver exceptional results.<br><br> My technical skills are broad, with a deep understanding of software development methodologies, cloud computing, big data analytics, and distributed systems. I have a strong understanding of industry trends and can provide valuable insights into emerging technologies that can help drive innovation and improve efficiency.<br><br> I am a strategic thinker who can effectively communicate complex technical concepts to non-technical stakeholders. My ability to collaborate with senior management, product managers, and other key stakeholders enables me to make informed decisions that align with the company's business objectives.</div>"}} />

                <div className="resume">
                    <img src={ResumePic} className="resume-pic"/>
                    <a href="javascript:void(0)" className="resume-button">
                        <p>Resume</p>
                    </a>
                </div>

            </main>
        </Layout>
    )
}

export default AboutPage;