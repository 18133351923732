export const menus = [
    {
      label: 'Home',
      url: '/'
    },
    {
      label: 'About',
      url: '/about'
    },
    {
      label: 'Blog',
      url: '/404'
    }
  ]