import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

// ASSET/COMPONENT IMPORTS
import index1 from './assets/images/index1.png'
import index2 from './assets/images/index2.png'
import index3 from './assets/images/index3.png'
import index4 from './assets/images/index4.png'
import { social } from './utils/social';
import { menus } from './utils/menus';
import Hamburger from './assets/svg/hamburger-lines.svg';
import HamburgerX from './assets/svg/hamburger-x-svg.svg';
import Layout from './components/layout';
import './assets/style.scss';


function App() {

  // STATES
  const [ portraits ] = useState([index1, index2, index3, index4]);
  const [ currentPortrait, setCurrentPortraits ] = useState(portraits[2]);
  const [ menuClicked, setMenuClicked ] = useState(false);
  
  // DEVICE DETECT
  useEffect(() => {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      setInterval(() => setCurrentPortraits(portraits[Math.floor(Math.random() * Math.floor(3))]), 3000)
    }
  }, [portraits]);


  // MENUITEM HOVERS
  const menuHover = (e) => {
    switch(e.target.innerText) {
      case "About":
        setCurrentPortraits(portraits[1]);
        break;
      case "Blog":
        setCurrentPortraits(portraits[0]);
        break;
      case "Contact":
        setCurrentPortraits(portraits[3]);
        break;
      default:
        setCurrentPortraits(portraits[2]);
    }
  }

  const toggleMenu = () => {
    return (
      <div className="menu-overlay">
        <p>oliverfeher.com</p>
        <button className="menu-close" onClick={() => setMenuClicked(false)}>
          <img src={HamburgerX} alt="hamburger menu close" />
        </button>
        {menus.map((menu, idx) => {
          return (
            <Link className="mobile-menu-item" to={menu.url} key={idx} onMouseLeave={()=> setCurrentPortraits(portraits[2])} onMouseEnter={menuHover}>{menu.label}</Link>
          )
        })}
        <a className="mobile-menu-item" href="https://calendly.com/oliverfeher/15min">Contact</a>
      </div>
    )
  }
  return (
    <Layout hideNav={true}>
      <main>
        <button className="menu-index" onClick={() => setMenuClicked(true)}>
          <img src={Hamburger} alt="hamburger menu" />
        </button>

        {menuClicked ? toggleMenu() : null}

        <div className="portrait-container">
          <img src={currentPortrait} alt="portrait headshot" />
        </div>

        <div className="intro-wysiwyg"><h3>Hi, my name is Oliver!</h3></div>
        <div className="intro-wysiwyg"><h3>Director of Engineering @ Pillar4 Media.</h3></div>

        <div className="menus-container">
          {menus.slice(1).map((menu, idx) => {
            return (
              <Link className="menu-item" to={menu.url} key={idx} onMouseLeave={()=> setCurrentPortraits(portraits[2])} onMouseEnter={menuHover}>{menu.label}</Link>
            )
          })}
          <a className="menu-item" href="https://calendly.com/oliverfeher/15min">Contact</a>
        </div>

        <div className="social-icons">
          {social.map((icon, idx) => {
            return <a key={idx} rel="noreferrer" target="_blank" href={icon.url}><img src={icon.image} alt="social icon" /></a>
          })}
        </div>
      </main>
    </Layout>
  )
};


export default App;
