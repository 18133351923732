import React, { useEffect } from 'react'
import Layout from '../components/layout';
import { useNavigate } from 'react-router-dom';

import portraitImage from '../assets/images/index5.png';

export default ({ data }) => {

    const navigate = useNavigate();

    useEffect(() => {
       setTimeout(() => navigate('/'), 3000);
    }, [])
    
    return (
        <Layout>
            <main>
                <div className="portrait-container">
                    <img src={portraitImage} alt="portrait headshot" />
                </div>

                <div className="intro-wysiwyg" dangerouslySetInnerHTML={{__html: "<h3>Still working on this...</h3>"}} />
            </main>
        </Layout>
    )
}
