import React from 'react';
import { render } from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Error from './pages/error';
import About from './pages/about';
import App from './App';


render(
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}/>
      <Route path="about" element={<About />}/>
      <Route path="404" element={<Error />}/>
    </Routes>
    </BrowserRouter>,
  document.getElementById('root')
);